import React, { useState } from 'react'
import { Fab } from '@mui/material'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import api from '../../api'
import { Trans, useTranslation } from "react-i18next";

// import NewSchoolForm from '../NewSchoolForm/NewSchoolForm'

interface School {
    id: string;
    address: string;
    name: string;
    city: string;
    province: string;
}

interface Grade {
    name: string;
    value: string;
}

export default function ChildForm({
    childrenData,
    setChildrenData,
    onSubmit,
    addNewChild,
    showValidationError
}) {
    const { t } = useTranslation();
    const [hasSearched, setHasSearched] = useState(false);
    const [currentSchoolKey, setCurrentSchoolKey] = useState('1')
    const [nextSchoolKey, setNextSchoolKey] = useState('2')
    const [matchingSchools, setMatchingSchools] = useState([])
    const [currentSchoolTmpLabel, setCurrentSchoolTmpLabel] = useState('')
    const [nextSchoolTmpLabel, setNextSchoolTmpLabel] = useState('')
    const [loadingSchools, setLoadingSchools] = useState(false);
    const grades = [
        { name: "Not yet in school / Pas encore à l'école", value: 'NA' },
        { name: "Pre-Primary/Jr Kindergarten / Pré-maternelle / Jardin d'enfants junior", value: 'JK' },
        { name: "Primary/Kindergarten / Maternelle / Jardin d'enfants", value: 'K' },
        { name: 'Grade 1 / Niveau 1', value: '1' },
        { name: 'Grade 2 / Niveau 2', value: '2' },
        { name: 'Grade 3 / Niveau 3', value: '3' },
        { name: 'Grade 4 / Niveau 4', value: '4' },
        { name: 'Grade 5 / Niveau 5', value: '5' },
        { name: 'Grade 6 / Niveau 6', value: '6' },
        { name: 'Grade 7 / Niveau 7', value: '7' },
        { name: 'Grade 8 / Niveau 8', value: '8' },
        { name: 'Grade 9 / Niveau 9', value: '9' },
        { name: 'Grade 10 / Niveau 10', value: '10' },
        { name: 'Grade 11 / Niveau 11', value: '11' },
        { name: 'Grade 12 / Niveau 12', value: '12' },
    ]

    async function fetchSchools(event: any) {
        setLoadingSchools(true);
        const response = await api.get('/schools', {
            params: { name: event.target.value }
        })
        setMatchingSchools(response.data)
        setLoadingSchools(false);
        setHasSearched(true);
    }

    const setChildProperty = (property, value) => {
        let childObj = childrenData[0]
        childObj = {
            ...childObj,
            [property]: value,
        }
        setChildrenData(
            [childObj].concat(childrenData.slice(1))
        )

        return childObj;
    }

    const noOptionsLabel = () => {
        if (!hasSearched) return t("pledge.child.searchSchools");

        return t("pledge.child.noSchoolsFound");
    }

    function validateName(name) {
        return name.length >= 1;
    }

    function validateChildInput(childObj) {
        if (
            !(
                validateName(childObj.first_name) &&
                validateName(childObj.last_name)
            )
        ) {
            showValidationError(t("pledge.child.validation.invalidName"));
            return false;
        }

        const validGrades = grades.map((grade: Grade) => grade.value);
        if (!validGrades.find(grade => childObj.grade === grade)) {
            showValidationError(t("pledge.child.validation.invalidGrade"));
            return false;
        }

        if (childObj.school_id.length === 0 && childObj.grade !== 'NA') {
            showValidationError(t("pledge.child.validation.missingSchool"));
            return false;
        }

        showValidationError(null);
        return true
    }

    const [isValidInput, setIsValidInput] = useState(true);

    function validateAndSubmit() {
        const childObj = childrenData[0]
        const result = validateChildInput(childObj)
        if (result !== isValidInput) setIsValidInput(result)

        if (result) {
            onSubmit();
        }
    }

    return (
        <div className="form__fields">
            <h2 className="form__subtitle text-[24px]  font-bold text-[#2e4049]">
                Student Pledging To Wait
            </h2>
            <label className="form__input-label">
                {t("pledge.child.dateLabel")}
                <input
                    className="form__input-field"
                    type="text"
                    readOnly
                    value={new Date(Date.now()).toLocaleString().split(',')[0]}
                />
            </label>
            <label className="form__input-label">
                {t("pledge.child.firstNameLabel")}
                <input
                    className="form__input-field"
                    type="text"
                    value={childrenData[0].first_name}
                    onChange={(e) => {
                        const childObj = setChildProperty('first_name', e.target.value);
                        // let result = validateChildInput(childObj)
                        // if (result !== isValidInput) setIsValidInput(result)
                    }}
                />
            </label>

            <label className="form__input-label">
                {t("pledge.child.lastNameLabel")}
                <input
                    className="form__input-field"
                    type="text"
                    value={childrenData[0].last_name}
                    onChange={(e) => {
                        setChildProperty('last_name', e.target.value);
                        // let result = validateChildInput(childObj)
                        // if (result !== isValidInput) setIsValidInput(result)
                    }}
                />
            </label>
            <label className="form__input-label">
                {t("pledge.child.gradeLabel")}
                <Autocomplete
                    disablePortal
                    options={grades}
                    getOptionLabel={(option: Grade) => {
                        return `${option['name']}`
                    }}
                    onChange={(_, value) => {
                        const grade = value as Grade;
                        setChildProperty('grade', grade?.value);
                    }}
                    sx={{ marginBottom: 2 }}
                    renderInput={(params) => <TextField {...params} />}
                    isOptionEqualToValue={(opt: Grade, value: Grade) => opt.value === value.value}
                />
            </label>

            {childrenData[0].grade && childrenData[0].grade !== 'NA' && (
                <>
                    <Autocomplete
                        key={currentSchoolKey}
                        disablePortal
                        loading={loadingSchools}
                        id="combo-box-demo"
                        options={matchingSchools}
                        getOptionLabel={(option) => {
                            return `${option['name']}, ${option['city']}, ${option['province']}`
                        }}
                        noOptionsText={noOptionsLabel()}
                        onChange={(_, value) => {
                            const school = value as School | null;
                            const schoolId = school?.id || '';

                            const childObj = setChildProperty('school_id', schoolId);
                            let result = validateChildInput(childObj)
                            if (result !== isValidInput) setIsValidInput(result)
                        }}
                        sx={{ marginBottom: 2 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                helperText={t("pledge.child.searchSchools")}
                                label={
                                    currentSchoolTmpLabel
                                        ? currentSchoolTmpLabel
                                        : t("pledge.child.currentSchoolLabel")
                                }
                                onChange={(e) => {
                                    setCurrentSchoolTmpLabel('')
                                    fetchSchools(e)
                                }}
                            />
                        )}
                    />

                    <Autocomplete
                        key={nextSchoolKey}
                        disablePortal
                        id="combo-box-demo"
                        options={matchingSchools}
                        loading={loadingSchools}
                        getOptionLabel={(option) => {
                            return `${option['name']}, ${option['city']}, ${option['province']}`
                        }}
                        noOptionsText={noOptionsLabel()}
                        onChange={(_, value) => {
                            const school = value as School | null;
                            const schoolId = school?.id || '';

                            const childObj = setChildProperty('next_school_id', schoolId);
                            let result = validateChildInput(childObj)
                            if (result !== isValidInput) setIsValidInput(result)
                        }}
                        sx={{ marginBottom: 3 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                helperText={t("pledge.child.searchSchools")}
                                label={
                                    nextSchoolTmpLabel
                                        ? nextSchoolTmpLabel
                                        : t("pledge.child.nextSchoolLabel")
                                }
                                onChange={(e) => {
                                    setNextSchoolTmpLabel('')
                                    fetchSchools(e)
                                }}
                            />
                        )}
                    />
                </>
            )}

            <p>
                <Trans i18nKey="pledge.child.cannotFindSchool">
                    If you can't find your school above, please <a href="mailto:info@unpluggedcanada.com?subject=Missing%20school%20request" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">get in touch</a> so we can add it
                </Trans>
            </p>

            <div className="form__button">
                <Fab
                    variant="extended"
                    onClick={() => {
                        addNewChild()
                        setMatchingSchools([])
                        setIsValidInput(false)
                        setCurrentSchoolKey(currentSchoolKey + '1')
                        setNextSchoolKey(nextSchoolKey + '2')
                    }}
                >
                    {t("pledge.child.addAnotherChild")}
                </Fab>
                <Fab
                    style={{
                        color: 'white',
                        width: '10rem',
                        marginLeft: '1rem',
                    }}
                    color="primary"
                    variant="extended"
                    onClick={validateAndSubmit}
                >
                    {t("pledge.child.submit")}
                </Fab>
            </div>
        </div>
    )
}
