import React, { ChangeEvent, useState } from "react";
import { Checkbox, Fab, FormControlLabel } from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";

export default function GuardianForm({
  guardianData,
  setGuardianData,
  postGuardian,
}) {
  const { t } = useTranslation();
  const Provinces = [
    { code: "AB", name: "Alberta" },
    { code: "BC", name: "British Columbia" },
    { code: "MB", name: "Manitoba" },
    { code: "NB", name: "New Brunswick" },
    { code: "NL", name: "Newfoundland and Labrador" },
    { code: "NT", name: "Northwest Territories" },
    { code: "NS", name: "Nova Scotia" },
    { code: "NU", name: "Nunavut" },
    { code: "ON", name: "Ontario" },
    { code: "PE", name: "Prince Edward Island" },
    { code: "QC", name: "Quebec" },
    { code: "SK", name: "Saskatchewan" },
    { code: "YT", name: "Yukon" },
  ];
  function validateName(name) {
    return name.length >= 1;
  }

  function validateEmail(email) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }

  function validateAllInput(guardianData) {
    if (!validateName(guardianData.first_name)) {
      return false;
    }
    if (!validateName(guardianData.last_name)) {
      return false;
    }
    if (!validateEmail(guardianData.email)) {
      return false;
    }

    if (
      (guardianData.province.length === 2 && guardianData.city.length > 1) ===
      false
    ) {
      return false;
    }

    return true;
  }
  const [isValidInput, setIsValidInput] = useState(
    validateAllInput(guardianData)
  );

  return (
    <div className="form__fields">
      <h2 className="form__subtitle  text-[24px]  font-bold text-[#2e4049]">
        {t("pledge.guardian.title")}
      </h2>

      <label className="form__input-label">
        {t("pledge.guardian.firstNameLabel")}
        <input
          className="form__input-field"
          type="text"
          value={guardianData.first_name}
          onChange={(e) => {
            setGuardianData({ ...guardianData, first_name: e.target.value });
            let result = validateAllInput({
              ...guardianData,
              first_name: e.target.value,
            });
            if (result !== isValidInput) setIsValidInput(result);
          }}
        />
      </label>

      <label className="form__input-label">
        {t("pledge.guardian.lastNameLabel")}
        <input
          className="form__input-field"
          type="text"
          value={guardianData.last_name}
          onChange={(e) => {
            setGuardianData({ ...guardianData, last_name: e.target.value });
            let result = validateAllInput({
              ...guardianData,
              last_name: e.target.value,
            });
            if (result !== isValidInput) setIsValidInput(result);
          }}
        />
      </label>
      <label className="form__input-label">
        {t("pledge.guardian.emailLabel")}
        <input
          className="form__input-field"
          type="text"
          value={guardianData.email}
          onChange={(e) => {
            setGuardianData({ ...guardianData, email: e.target.value });
            let result = validateAllInput({
              ...guardianData,
              email: e.target.value,
            });
            if (result !== isValidInput) setIsValidInput(result);
          }}
        />
      </label>

      <label className="form__input-label">
        {t("pledge.guardian.cityLabel")}
        <input
          className="form__input-field"
          type="text"
          value={guardianData.city}
          onChange={(e) => {
            setGuardianData({ ...guardianData, city: e.target.value });
            let result = validateAllInput({
              ...guardianData,
              city: e.target.value,
            });
            if (result !== isValidInput) setIsValidInput(result);
          }}
        />
      </label>
      <Box sx={{ minWidth: 120 }}>
        <FormControl required fullWidth>
          <InputLabel id="demo-simple-select-label">Province</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={guardianData.province}
            label={t("pledge.guardian.provinceLabel")}
            required={true}
            onChange={(e) => {
              setGuardianData({ ...guardianData, province: e.target.value });
              let result = validateAllInput({
                ...guardianData,
                province: e.target.value,
              });
              if (result !== isValidInput) setIsValidInput(result);
            }}
          >
            {Provinces.map((province) => {
              return (
                <MenuItem key={province.code} value={province.code}>
                  {province.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>

      <FormControlLabel
        control={<Checkbox defaultChecked />}
        label={t("pledge.guardian.shareMyEmail")}
        onChange={(e, checked: boolean) => {
          setGuardianData({ ...guardianData, granted_permission_to_share_details_with_other_parents: checked})
        }} />

      <FormControlLabel
        control={<Checkbox defaultChecked={false} />}
        label={t("pledge.guardian.signUpToNewsletter")}
        onChange={(e, checked: boolean) => {
          setGuardianData({ ...guardianData, sign_up_to_newsletter: checked})
        }} />

      <div className="form__button">
        <Fab
          sx={{ marginTop: 2 }}
          style={{ color: "white", width: "10rem" }}
          color="primary"
          variant="extended"
          onClick={postGuardian}
          disabled={!isValidInput}
        >
          {t("pledge.guardian.next")}
        </Fab>
      </div>
    </div>
  );
}
