import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './locales/en.json'
import fr from './locales/fr.json'

const resources = {
    en: { translation: en },
    fr: { translation: fr },
}

i18n.use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: 'en',
        load: 'languageOnly',
        supportedLngs: ['en', 'fr'],
        resources,

        interpolation: {
            escapeValue: false, // react already safes from xss
        },

        detection: {
            lookupQuerystring: 'lang',
        },
    })

export default i18n
